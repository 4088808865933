import React, { useEffect } from "react";
import "./privacypolicy.scss";

const PrivacyPolicy = () => {
  useEffect(() =>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <div className="privacypolicy-all-content-alignment">
      <div className="new-container">
      <h1>Alladdin Privacy Policy</h1>
      <h2>Pre-Alladdinpay</h2>
      <p>
        Alladdin ("we," "us," or "our") values your privacy and is committed to safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when using the Alladdin Subscription
        Management Chrome Extension and related services. By using the extension, you agree to the practices described here.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>a. User-Provided Subscription Information</h3>
      <ul>
        <li>
          <strong>Subscription Details:</strong> Users input details about subscriptions, including service names, payment amounts, renewal dates, and other information necessary to provide insights and reminders.
        </li>
        <li>
          <strong>Google Identity API:</strong> Users have the option to log in via their Google account. This enables us to access basic profile information and email address using OAuth2 scopes to authenticate and sync user-specific data across
          devices, allowing for a seamless and personalized experience.
        </li>
      </ul>
      <h3>b. Usage Information</h3>
      <ul>
        <li>
          <strong>Extension Activity:</strong> We collect information about how users interact with the extension, such as feature usage and preferences. This data helps us improve and enhance the functionality of the service.
        </li>
        <li>
          <strong>Financial Insights:</strong> Based on user-inputted subscription information, Alladdin calculates spending insights, including past spending, renewal schedules, upcoming payments, and total subscription expenses over time.
        </li>
      </ul>
      <h3>c. Browser Permissions</h3>
      <ul>
        <li>
          <strong>Website Access:</strong> To support tracking subscriptions from various services, the extension requests broad host permissions, allowing interaction across websites users select to manage subscriptions. This access is strictly
          limited to facilitate full functionality and does not involve data collection from those sites.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>The information you provide is used to:</p>
      <ul>
        <li>
          <strong>Subscription Management:</strong> Allow users to track, view, and organize subscription details effectively within the Alladdin platform.
        </li>
        <li>
          <strong>Notifications & Alerts:</strong> Send reminders and notifications for upcoming renewals and payments based on the dates you have entered.
        </li>
        <li>
          <strong>Financial Insights:</strong> Offer summaries of subscription spending, renewals, and a calendar view of scheduled payments.
        </li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p>We respect your privacy and do not sell, rent, or lease your personal information to third parties. Your data may be shared in the following cases:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share data with trusted third-party providers who assist in delivering Alladdin’s services, such as cloud hosting or customer support. These providers are bound by confidentiality agreements and
          use the data only as instructed by us.
        </li>
        <li>
          <strong>Legal Compliance:</strong> If required by law or government request, we may disclose your information.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred to a new entity.
        </li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        Alladdin employs industry-standard security measures, including encryption, access controls, and monitoring, to protect your personal information. While we strive to protect your data, please be aware that no method of electronic transmission
        or storage is 100% secure.
      </p>

      <h2>5. User Rights and Choices</h2>
      <ul>
        <li>
          <strong>Access and Update:</strong> Users may access and update their subscription information within account settings.
        </li>
        <li>
          <strong>Deletion:</strong> You can request deletion of your account and associated information; however, certain data may be retained as required by legal or business obligations.
        </li>
        <li>
          <strong>Notification Preferences:</strong> Users can control in-app notifications and email alerts within the settings.
        </li>
      </ul>

      <h2>6. Data Retention</h2>
      <p>We retain your information only as long as necessary to provide services, comply with legal obligations, and resolve disputes.</p>

      <h2>7. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to recognize your device and optimize the extension’s functionality. You may adjust your browser settings to manage cookies.</p>

      <h2>8. Children’s Privacy</h2>
      <p>The Alladdin extension is intended for users aged 16 and older. We do not knowingly collect personal information from children under 16.</p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically. Changes will be posted here, and the effective date will be updated. We encourage users to review this policy regularly.</p>

      <h2>10. Contact Us</h2>
      <p>For questions or concerns regarding this Privacy Policy, please contact us at:</p>
      <p>
        <strong>Email:</strong> <a href="mailto:info@alladdinco.com">info@alladdinco.com</a>
      </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
