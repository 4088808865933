import HeroImage from "../../../assets/images/herotabimg.webp";
const HeroBanner = () => {
  return (
    <div className="py-10 mobile:py-60">
      <div className="max-w-1340 mx-auto px-5 mobile:px-30">
        <div className="grid grid-cols-[700px_1fr] gap-0 items-center tab:grid-cols-1 mobile:gap-30">
          <div>
            <h1 className="text-[64px] tracking-normal text-black300 font-bold mb-5 mobile:text-[32px] mobile:leading-normal">Simplify Your Subscriptions, Amplify Your Savings</h1>
            <p className="text-lg mobile:text-base mobile:leading-8 tracking-normal text-gray600 max-w-[600px] ">
              From tracking free trials to providing in-depth spending insights, we're here to ensure you never lose sight of where your money goes. Get ready to navigate your digital services with unprecedented ease and clarity
            </p>
            <div className="flex items-center gap-5 pt-8 mobile:grid mobile:grid-cols-1">
              <button className="border-none cursor-pointer text-white rounded-full py-4 px-10 text-lg mobile:text-base mobile:py-3 font-medium tracking-normal bg-black300">Install Extension</button>
              <a class="" href="/signup">
                <span className="text-base text-black300 font-medium underline cursor-pointer block mobile:text-center">Start 30 days free trial</span>
              </a>
            </div>
          </div>
          <div>
            <img src={HeroImage} className="w-full block" alt="HeroImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
